<template>
  <div class="examination">
    <van-nav-bar title="ཚོད་ལྟའི་རྒྱུགས།" left-arrow @click-left="onClickLeft" />
    <main>
      <div class="topic">
        <div class="option">
          {{
            countForm.type == 1
              ? "བརྡར་གཅོད།"
              : countForm.type == 2
              ? "རྐྱང་འདེམས།"
              : "སྣ་མང་གདམ་གསེས།"
          }}
        </div>
        <div class="htmcontent" v-html="countForm.content"></div>
        <img
          v-if="vocieshow"
          class="voice"
          src="../assets/image/voicebefore.png"
          alt=""
          @click="play(1)"
        />
        <img
          v-else
          class="voice"
          src="../assets/image/voiceafter.gif"
          alt=""
          @click="play(2)"
        />
        <audio ref="audio" :src="countForm.audios || countForm.audios[0] || countForm.audios[0].url" style="opacity: 0" @ended="myFunction"></audio>
        <div class="topicImg" v-if="countForm.imgList">
          <van-image
            fit="contain"
            v-for="(item, index) in countForm.imgList"
            :key="index"
            :src="item.url || item"
            @click="HandleclickImg(item)"
          />
        </div>
        <!-- 单选判断题 -->
        <div v-if="countForm.type === 1 || countForm.type === 2">
          <div
            class="choise"
            v-for="(item, index) in countForm.optionCreates"
            :key="index"
            @click="select(item, index)"
          >
            <div class="btn" v-if="countForm.cutindex !== index && index !== countForm.trueIndex">{{ item.optionNo }}</div>
            <img class="btn" v-if="countForm.cutindex === index && !item.isTrue" src="../assets/image/error.png" alt="错误">
            <img class="btn" v-if="index === countForm.trueIndex && item.isTrue" src="../assets/image/right.png" alt="正确">
            <p class="text" v-if="item.type == 1">{{ item.content }}</p>
            <img v-else class="optionsImg" :src="item.content" alt="" />
          </div>
        </div>
        <!-- 多选题 选择之前 -->
        <div v-if="countForm.type === 3 && !countForm.isSelect">
          <!-- <van-checkbox-group v-model="result">
            <van-checkbox 
              v-for="(item, index) in countForm.optionCreates" 
              :key="index" 
              :name="item.optionNo"
              :value="item.optionNo"
              @click="look(index)">
              <span class="btn" :style="result.includes(item.optionNo) ? 'background: #1989fa; color:#fff;' : ''">{{item.optionNo}}</span>
              <span class="text">{{item.content}}</span>
            </van-checkbox>
          </van-checkbox-group> -->
          <div
            class="choise"
            v-for="(item, index) in countForm.optionCreates"
            :key="index"
            @click="look(item)"
          >
            <span class="btn" :style="result.includes(item.optionNo) ? 'background: #1989fa; color:#fff;' : ''">{{item.optionNo}}</span>
            <span class="text">{{item.content}}</span>
          </div>
        </div>
        <!-- 多选题 选择之后 -->
        <div v-if="countForm.type === 3 && countForm.isSelect">
          <div
            class="choise"
            v-for="(item, index) in countForm.optionCreates"
            :key="index"
          >
            <div class="btn" v-if="!countForm.result.includes(index) && !countForm.isSelect">{{item.optionNo}}</div>
            <img class="btn" v-if="!item.isTrue && countForm.isSelect" src="../assets/image/error.png" alt="错误">
            <img class="btn" v-if="item.isTrue && countForm.isSelect" src="../assets/image/right.png" alt="正确">
            <span class="text" v-if="item.type === 1">{{ item.content }}</span>
            <img v-else class="optionsImg" :src="item.content" alt="" />
          </div>
        </div>
        <div v-if="countForm.type == 3" class="regist">
          <van-button type="info" @click="registanser">ངོས་འཛིན་</van-button>
        </div>
      </div>
      <div class="answer" v-if="countForm.ansShow">
        <p class="anwertext" v-if="countForm.type === 1 ">དྲིས་ལན།： {{countForm.trueIndex === 0 ? 'ཡང་དག་པ།' : 'ནོར་འཁྲུལ།'}}</p>
        <p class="anwertext" v-if="countForm.type === 2 ">དྲིས་ལན།： {{countForm.trueIndex === 0 ? 'ཀ' : countForm.trueIndex === 1 ? 'ཁ' : countForm.trueIndex === 2 ? 'ག' : 'ང'}}</p>
        <p class="anwertext" v-if="countForm.type === 3">དྲིས་ལན།： {{ JSON.parse(countForm.checkvalue).join('、') }}</p>
        <div class="videoWrap">
          <div class="videotitle">
            <div class="line"></div>
            <p class="title">བརྙན་རིས་དབྱེ་ཞིབ།</p>
          </div>
          <div class="video">
            <video :src="countForm.videos || countForm.videos[0] || countForm.videos[0].url" poster="@/assets/image/videoimg.png" controls="controls" webkit-playsinline=“true” playsinline=“true” x5-video-orientation=“landscape|portrait” x5-video-player-type=“h5-page”></video>
          </div>
        </div>
      </div>
    </main>
    <div class="bottom">
      <div class="bottomone">
        <div class="content">
          <div class="next" @click="last">
            <van-icon name="arrow-left" />
            <p class="text">འདྲི་གཞི་སྔོན་མ།</p>
          </div>
          <div class="shu"></div>
          <div class="next" @click="next">
            <p class="text">འདྲི་གཞི་རྗེས་མ་ནི།</p>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
      <div class="bottomtwo">
        <div class="twocontent">
          <div class="time">
            <van-icon name="clock-o" />
            <van-count-down
              ref="countDown"
              :time="time"
              format="mm : ss "
              @change="changetime"
            />
          </div>
          <div v-if="errorTrue" class="examinations" @click="examinationpaper">རྒྱུགས་སྤྲོད་པ</div>
          <div class="correct fl">
            <van-icon name="checked" color="#6DD400" />
            <div class="number">{{rightList.length}}</div>
          </div>
          <div class="mistake fl">
            <van-icon name="clear" color="#E02020" />
            <div class="number">{{errorList.length}}</div>
          </div>
          <div class="allnum fl" @click="openpice">
            <van-icon name="apps-o" />
            <div class="nums">{{indexcount + 1}}/{{countList.length}}</div>
          </div>
        </div>
      </div>
    </div>
    <PlaneModule :panelShow.sync="panelShow" :altTime="altTime" :answerType="answerType" :residue="residue"></PlaneModule>
    <van-popup v-model="show" position="bottom" :style="{ height: '80%' }">
      <div class="popupmain">
        <div class="top">
          <div class="content">
            <div class="time fl">
              <van-icon name="clock-o" />
              <van-count-down
                :time="time"
                format="mm : ss "
                @change="changetime"
              />
            </div>
            <div class="correct fl">
              <van-icon name="checked" color="#6DD400" />
              <div class="number">{{rightList.length}}</div>
            </div>
            <div class="mistake fl">
              <van-icon name="clear" color="#E02020" />
              <div class="number">{{errorList.length}}</div>
            </div>
            <div class="allnum fl" @click="openpice">
              <van-icon name="apps-o" />
              <div class="nums">{{indexcount + 1}}/{{countList.length}}</div>
            </div>
          </div>
        </div>
        <div class="main">
          <div
            v-for="(item, index) in countList"
            :key="index"
            :class="item.isSelect ? item.selectTrue ? 'boxone' : 'boxtwo' : 'box' "
            :style="(index + 1) % 6 === 0 ? 'margin-right: 0;' : index === indexcount ? 'border: 1px solid #0775F6;' : ''"
            @click="choseount(index)"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import PlaneModule from "@/components/planeModule";
import { ImagePreview } from "vant";
import { addmastick } from '@/api/secondSelect'
import { vip } from '@/api'
export default {
  components: {
    PlaneModule,
  },
  data() {
    return {
      residue: 0,
      answerType: 1, // 1错题超过十道， 2答题正确超过90 3全部正确 4全部答完错了
      isSelect: false,
      result: [],
      // 正确索引
      trueIndex: '',
      // 当前单选或者多选选中下标
      cutindex: '',
      // 当前索引
      indexcount: 0,
      // 题目数组
      countList: [],
      // 做错题数组
      errorList: [],
      // 做对题数组
      rightList: [],
      // 当前题目对象
      countForm: {},
      // 当前是科一还是科四
      car: 1,
      // 是否点击了答案
      ansShow: false,
      // 播放音频图标是否显示
      vocieshow: true,
      option: "判断题",
      // 总题数
      allnum: 100,
      // 已做题数
      numsbefor: 2,
      // 正确题数
      sucesnum: 1,
      // 错误题数
      errnum: 1,
      // 定时
      time: 45 * 60 * 1000,
      // 弹窗
      panelShow: false,
      // 分钟
      minutes: 0,
      // 秒
      seconds: 0,
      // 剩余时间
      altTime: {},
      // 底部弹出框
      show: false,
      // 是否vip
      vip: false,
      errorTrue: true
    };
  },
  computed: {},
  watch: {},
  created() {
    vip().then(res => {
      this.vip = res.data
      if (this.$route.query.id == 1 || this.$route.query.id == 2) {
        this.countList = JSON.parse(localStorage.getItem('tixing'))
        this.indexcount = 0
      } else if (this.$route.query.id == 8) {
        this.countList = JSON.parse(localStorage.getItem('tixing'))
        this.rightList = JSON.parse(localStorage.getItem('righttixing'))
        this.errorList = JSON.parse(localStorage.getItem('errortixing'))
        this.indexcount = 0
      } else if (this.$route.query.id == 3) {
        this.countList = JSON.parse(localStorage.getItem('tixing'))
        this.indexcount = 1
      } else {
        this.countList = JSON.parse(localStorage.getItem('errortixing'))
        this.errorList = JSON.parse(localStorage.getItem('errortixing'))
        this.errorTrue = JSON.parse(localStorage.getItem('errortixing')).length > 0 ? false : true
        this.indexcount = 0
      }
      this.countForm = this.countList[this.indexcount]
    })
    
  },
  mounted() {
    this.$refs.countDown.start();
  },
  methods: {
    // 预览图片
    HandleclickImg(e) {
      ImagePreview({
        images: [`${e}` || `${e.url}`],
        onClose() {

        }
      })
    },
    // 播放音乐
    play(e) {
      this.vocieshow = !this.vocieshow;
      if (e === 1) {
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }
    },
    myFunction() {
      this.vocieshow = !this.vocieshow
    },
    // 返回上一个页面
    onClickLeft() {
      // console.log(localStorage.getItem('tixing'), localStorage.getItem('errortixing'), localStorage.getItem('vip'), localStorage.getItem('righttixing'))
      this.$router.push('/');
      localStorage.removeItem('tixing')
      localStorage.removeItem('errortixing')
      localStorage.removeItem('vip')
      localStorage.removeItem('righttixing')
    },
    /** 交卷 */
    examinationpaper() {
      this.residue = this.countList.length - this.errorList.length - this.rightList.length
      this.$refs.countDown.pause();
      this.panelShow = true;
      this.altTime = {
        mm: this.minutes,
        ss: this.seconds,
      };
    },
    // 获取当前时间
    changetime(e) {
      this.minutes = e.minutes;
      this.seconds = e.seconds;
    },
    // 打开底部弹框
    openpice() {
      this.show = true;
    },
    // 下一题
    next() {
      this.vocieshow = true
      if (this.indexcount == 1 && !this.vip) {
        localStorage.setItem('tixing', JSON.stringify(this.countList))
        this.$router.push({
          path: "/pay",
          query: {
            ip: 1
          }
        });
      } else if (this.indexcount == this.countList.length - 1) {
        return false;
      } else {
        this.indexcount++;
        this.countForm = this.countList[this.indexcount];
        console.log(this.indexcount);
      }
      if (this.countList[this.indexcount].type === 3) {
        this.result = this.countList[this.indexcount].result
      }
    },
    // 上一题
    last() {
      if (this.indexcount == 0) {
        return false;
      } else {
        this.indexcount--;
        this.countForm = this.countList[this.indexcount];
      } 
      if (this.countList[this.indexcount].type === 3) {
        this.result = this.countList[this.indexcount].result
      }
    },
    // 选择答案
    select(e, index) {
      if(this.countList[this.indexcount].isSelect) { // 是否已经选择过 
        return false
      } else {
        this.countList[this.indexcount].isSelect = true
        // 存当前选择下标
        this.countForm.cutindex = index
        this.cutindex = index
        // 当前选择题数组
        const arr = this.countForm.optionCreates
        // 对题所在索引
        const ids = arr.findIndex(item => item.isTrue)
        this.countForm.trueIndex = ids
        this.trueIndex = ids
        this.countForm.ansShow = true
      } 
      if (e.isTrue === true) {
        this.rightList.push(this.countForm)
        this.countForm.selectTrue = true
        this.next()
      } else {
        this.errorList.push(this.countForm)
        addmastick({ids: [this.countForm.id]}).then(res => {})
        this.countForm.selectTrue = false
      }
    },
    look(item) {
      if (this.result.length > 1) {
        if (this.result.indexOf(item.optionNo) > -1) {
          this.result.splice(this.result.indexOf(item.optionNo), 1)
        } else {
          this.result.push(item.optionNo)
        }
      } else {
        this.result.push(item.optionNo)
      }
    },
    // 多选题确认按钮
    registanser() {
      if (this.countForm.isSelect) return false
      this.countForm.ansShow = true
      this.isSelect = true
      this.countForm.isSelect = true
      const arr = this.countForm.optionCreates
      const trueList = []
      // 遍历获取对的题目
      arr.forEach((item, index) => {
        if (item.isTrue) {
          trueList.push(item.optionNo)
        }
      });
      // 对藏语的答案顺序排序
      let arrList = []
      this.result.forEach(item => {
        if (item == 'ཀ') {
          arrList[0] = 'ཀ'
        } 
        if (item == 'ཁ') {
          arrList[1] = 'ཁ'
        } 
        if (item == 'ག') {
          arrList[2] = 'ག'
        } 
        if (item == 'ང') {
          arrList[3] = 'ང'
        }
      })
      let chooseList = []
      arrList.forEach(item => {
        if (item) {
          chooseList.push(item)
        }
      })
      console.log(JSON.stringify(trueList), JSON.stringify(chooseList), JSON.stringify(trueList) == JSON.stringify(chooseList))
      if (JSON.stringify(trueList) == JSON.stringify(chooseList)) {
        this.rightList.push(this.countForm)
        this.countForm.selectTrue = true
        this.next()
      } else {
        this.errorList.push(this.countForm)
        this.countForm.selectTrue = false
      }
      // 合并数组并去除相同项 然后复制给当前题目
      this.countForm.result = Array.from(new Set(trueList.concat(this.result)))
    },
    // 底部弹窗打开选择题目
    choseount(e) {
      if (e >= 1 && this.vip === false) {
        this.$router.push("/pay");
      } else {
        this.indexcount = e
        this.countForm = this.countList[e]
        this.show = false
      }
      if (this.countList[this.indexcount].type === 3) {
        this.result = this.countList[this.indexcount].result
      }
    },
  },
};
</script>

<style scoped lang="scss">
.examination {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: linear-gradient( 90deg, #F4F8FB 0%, #FAF6F5 100%);
  .htmcontent{
    display: inline;
  }
  .fl {
    display: flex;
    align-items: center;
  }
  main {
    flex: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .topic {
      background: #fff;
      padding: 12px;
      .option {
        height: 20px;
        min-width: 29px;
        padding: 0 6px;
        background-image: url("../assets/image/option.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.16);
        text-align: center;
        display: inline-block;
        line-height: 20px;
      }
      .voice {
        width: 20px;
        height: 20px;
        margin-bottom: -3px;
        margin-left: 4px;
        display: inline-block;
        transform: translateY(6px);
      }
      .topicImg {
        height: 120px;
        margin: 20px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .van-image{
          height: 120px;
        }
        .imgsd {
          width: 152px;
          height: auto;
        }
      }
      .van-checkbox__label .btn{
        width: 24px;
        height: 24px;
        min-width: 24px;
        text-align: center;
        line-height: 24px;
        font-size: 18px;
        font-family: Kailasa;
        color: #000000;
        border-radius: 50%;
        display: inline-block;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
      }
      .van-checkbox__label .text {
        padding-left: 10px;
        font-weight: bold;
        font-family: Kailasa;
        font-size: 16px;
        color: #000000;
      }
      .van-checkbox {
        margin-top: 10px;
        padding: 5px 0;
      }
      ::v-deep .van-checkbox__icon .van-icon {
        display: none;
        // width: 24px;
        // height: 24px;
        // min-width: 24px;
        // border: none;
        // box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
      }
      .choise {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .btn {
          width: 24px;
          height: 24px;
          min-width: 24px;
          text-align: center;
          line-height: 24px;
          font-size: 18px;
          font-family: Kailasa;
          color: #000000;
          border-radius: 50%;
          display: inline-block;
          background: #FFFFFF;
          box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
        }
        .text {
          padding-left: 10px;
          font-weight: bold;
          font-family: Kailasa;
          font-size: 16px;
          color: #000000;
        }
      }
      .regist{
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        margin-top: 5px;
        .van-button{
          width: 90px;
          height: 30px;
          border-radius: 15px;
        }
      }
      ::v-deep.van-checkbox{
        margin: 10px 0;
        align-items: initial;
        .van-checkbox__label{
          font-size: 14px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #171717;
          line-height: 18px;
        }
      }
    }
    .answer {
      margin-top: 8px;
      padding: 10px 12px;
      .anwertext {
        padding-left: 15px;
        padding-bottom: 7px;
        font-size: 18px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #171717;
        line-height: 26px;
      }
      .videoWrap {
        background: #f0f0f0;
        border-radius: 7px;
        padding: 17px 20px;
        .videotitle {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .line {
            width: 4px;
            height: 16px;
            background: #0091ff;
          }
          .title {
            padding-left: 8px;
            font-size: 14px;
            font-family: SourceHanSansSC-Medium, SourceHanSansSC;
            font-weight: 500;
            color: #171717;
            line-height: 16px;
          }
        }
        .video {
          width: 310px;
          height: 148px;
          margin-top: 13px;
          video{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .bottom {
    width: 375px;
    height: 104px;
    box-shadow: 0px 0px 2px 0px rgba(141, 141, 141, 0.22);
    .bottomone {
      width: 100%;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      .content {
        width: 345px;
        height: 52px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .next {
          display: flex;
          align-items: center;
          .text {
            font-size: 16px;
            font-family: SourceHanSansSC-Regular, SourceHanSansSC;
            font-weight: 400;
            color: #000;
            line-height: 10px;
            padding-left: 3px;
            opacity: 0.8;
            &:nth-child(1) {
              padding: 0;
              padding-right: 2px;
            }
          }
          i {
            color: #000000;
            font-size: 18px;
            margin-bottom: -10px;
            opacity: 0.5;
          }
        }
        .shu{
          width: 1px;
          height: 22px;
          margin-bottom: -4px;
          border-right: 1px solid #707070;
          opacity: 0.19;
        }
      }
    }
    .bottomtwo {
      width: 375px;
      height: 52px;
      background: #ffffff;
      box-shadow: 0px 10px 10px 0px rgba(221, 221, 221, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      .examinations {
        width: 60px;
        height: 25px;
        background: linear-gradient( 180deg, #6493FF 0%, #477EFE 100%);
        border-radius: 20px 20px 20px 20px;
        text-align: center;
        font-size: 14px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
        margin-left: 8px;
      }
      .twocontent {
        width: 345px;
        height: 52px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
          font-size: 18px;
          padding-right: 10px;
        }
        .time {
          display: flex;
          align-items: center;
        }
        .correct {
          margin-left: 32px;
        }
      }
    }
  }
  .popupmain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .top {
      width: 375px;
      height: 52px;
      background: #ffffff;
      box-shadow: 0px 2px 2px 0px rgba(221, 221, 221, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      .content {
        width: 345px;
        height: 52px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .main {
      padding: 20px 27px;
      flex: 1;
      overflow-y: scroll;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-content: flex-start;
      .box {
        width: 40px;
        height: 40px;
        border: 1px solid #f3f3f3;
        text-align: center;
        line-height: 40px;
        font-size: 18px !important;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #6e7278;
        border-radius: 50%;
        margin-bottom: 12px;
        margin-right: 10px;
      }
      .boxone{
        width: 40px;
        height: 40px;
        background: #BCEDC0;
        text-align: center;
        line-height: 40px;
        font-size: 18px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #00992A;
        border-radius: 50%;
        margin-bottom: 12px;
        margin-right: 10px;
      }
      .boxtwo{
        width: 40px;
        height: 40px;
        background: #FFD2D2;
        text-align: center;
        line-height: 40px;
        font-size: 18px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color:#E02020;
        border-radius: 50%;
        margin-bottom: 12px;
        margin-right: 10px;
      }
    }
  }
}
</style>
