<template>
  <van-popup class="vanpop" v-model="panelShow" round :style="{ height: '300px', width: '320px', borderRadius: '16px' }" :close-on-click-overlay="false">
    <p class="title" :style="answerType === 2 || answerType === 3 ? 'color:#17C400;' : 'color:#FF0000;'">{{answerType === 2 || answerType === 3 ? '成绩合格' : '成绩不合格'}}</p>
    <van-circle
      v-model="currentRate"
      :rate="rate"
      :color="answerType === 2 || answerType === 3 ? 'rgb(67, 208, 2)' : 'rgb(255, 0, 0)'"
      :stroke-width="100"
    />
    <p class="text">未答</p>
    <p class="topicnum" :style="answerType === 2 || answerType === 3 ? 'color:#17C400;' : 'color:#FF0000;'">{{residue}}题</p>
    <div class="residuWarp">
      <van-icon name="underway-o" />
      <p class="residuetime">剩余时间</p>
      <p style="padding-left:10px;line-height:24px;">{{altTime.mm}} : {{altTime.ss > 10 ? altTime.ss : `0${altTime.ss}`}}</p>
    </div>
    <div v-if="answerType === 2 || answerType === 1" class="continueWrap">
      <div class="boxbtn" @click="goresult(1)">现在交卷</div>
      <div class="boxbtn" :style="{ 'background-color': moduleDetail.btncolor}" @click="close">继续答题</div>
    </div>
    <div v-else class="nowgo" :style="answerType === 3 ? 'background:#17C400;' : 'background:#FF0000;'" @click="goresult(2)">现在交卷</div>
  </van-popup>
</template>

<script>
export default {
  name: 'planemodule',
  props: {
    panelShow: {
      type: Boolean
    },
    altTime: {
      type: Object
    },
    answerType: {
      type: Number
    },
    residue: {
      type: Number
    }
  },
  data() {
    return {
      moduleDetail: {
        title: '成绩不合格',
        titlecolor: 'color: rgba(255, 5, 0, 1);',
        circlebg: 'rgb(255 5 0)',
        numcolor: 'rgb(67, 208, 2)',
        continue: '继续答题',
        nowgo: '现在交卷',
        btncolor: 'rgb(67, 208, 2)'
      },
      currentRate: 0,
      rate: 80,
      number: 0
    }
  },
  mounted() {
    
  },
  methods: {
    close() {
      this.$emit('update:panelShow', false)
      this.$parent.$refs.countDown.start()
    },
    goresult(e) {
      localStorage.setItem('tixing', JSON.stringify(this.$parent.countList))
      localStorage.setItem('errortixing', JSON.stringify(this.$parent.errorList))
      localStorage.setItem('righttixing', JSON.stringify(this.$parent.rightList))
      this.$router.push({
        path: '/result',
        query: {
          id: this.$parent.countList[0].subject == 2 ? this.$parent.rightList.length * 2 : this.$parent.rightList.length
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.vanpop{
  display: flex;
  flex-direction: column;
  align-items: center;
  .title{
    text-align: center;
    padding-top: 20px;
    font-size: 24px;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    font-weight: 600;
    color: #FF0000;
    line-height: 35px;
  }
  .text{
    font-size: 18px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #171717;
    line-height: 26px;
    position: absolute;
    top: 102px;
  }
  ::v-deep .van-circle{
    margin-top: 24px;
  }
  .topicnum{
    font-size: 22px;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    font-weight: 500;
    color: #FF0000;
    line-height: 32px;
    position: absolute;
    top: 128px;
  }
  .residuWarp{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 23px;
    i{
      font-size: 18px;
    }
    .residuetime{
      font-family: PingFangSC;
      font-weight: 400;
      font-size: 14px;
      color: rgb(16, 16, 16);
      font-style: normal;
      letter-spacing: 0px;
      line-height: 20px;
      text-decoration: none;
      padding-left: .42rem;
    }
  }
  .continueWrap{
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 25px;
    .boxbtn{
      width: 120px;
      height: 35px;
      border-radius: 50px;
      font-size: 18px;
      font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 35px;
      text-align: center;
      background: #FF0000;
      &:nth-child(2){
        color: #fff;
        border-color:  rgb(67, 208, 2);
      }
    }
  }
  .nowgo{
    width: 175px;
    height: 35px;
    background: #17C400;
    border-radius: 17px;
    text-align: center;
    font-size: 18px;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 35px;
    margin-top: 20px;
  }
}
::v-deep.popmination{
    padding: .83rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
</style>
